import React, { Component } from 'react';

export const DEFAULT_STATE = {
    avt: true,
    coolmth: true,
    warmmth: true,
    spredt: true,
    coolq: true,
    warmq: true,
    twetq: true,
    tdryq: true,
    avrf: true,
    wetmth: true,
    drymth: true,
    coefvar: true,
    wetq: true,
    dryq: true,
    rfcoolq: true,
    rfhotq: true,
    algorithm: 'euclidean',
    species: 'unspecified'
};



export class MatchSettings extends Component {

    constructor(props) {
        super(props);
        this.state = { ...this.props.initialState };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(ev) {
        let target = ev.target;
        let oldState = { ...this.state };
        oldState[target.name] = target.type === 'checkbox' ? target.checked : target.value;
        this.setState(oldState);
    }

    render() {
        return(
        <div className="message-holder settings" style={this.props.style}>
            <div className="popupHeading">Match Settings</div>
               <form>
                   <br />
                <div>
                    <span className="hdgSetting">Species</span>
                    <input type="text" name="species" id="sppName"
                        value={this.state.species}
                        onChange={this.handleChange} />
                </div>
                <div>
                    <span className="hdgSetting">Algorithm</span>
                    <input type="radio" className="radioAlg" name="algorithm"
                        defaultChecked={this.state.algorithm === "euclidean"}
                        value="euclidean"
                        onChange={this.handleChange} />
                    Euclidean
                    <br />
                    <input type="radio" className="radioAlg" name="algorithm"
                        defaultChecked={this.state.algorithm === "closestStandardScore"}
                        value="closestStandardScore"
                        onChange={this.handleChange} />
                    Closest Standard Score
                </div>
                <div>
                    <span className="hdgSetting">Variables</span>
                    <div id="tempBoxes">
                        <span title="avt:  Annual Mean Temperature.">
                        <input type="checkbox" className="chkBxTemp" name="avt"
                            checked={this.state.avt}
                            onChange={this.handleChange} />
                        Annual Mean Temperature</span><br />

                        <span title="coolmth:  Mean temperature of coldest month.">
                        <input type="checkbox" className="chkBxTemp" name="coolmth"
                            checked={this.state.coolmth}
                            onChange={this.handleChange} />
                        Temp - coldest month</span><br />

                        <span title="warmmth:  Mean temperature of warmest month.">
                        <input type="checkbox" className="chkBxTemp" name="warmmth"
                            checked={this.state.warmmth}
                            onChange={this.handleChange} />
                        Temp - warmest month</span><br />

                        <span title="spredt:  Maximum minus minimum annual temperature (ie warmmth - coolmth).">
                        <input type="checkbox" className="chkBxTemp" name="spredt"
                            checked={this.state.spredt}
                            onChange={this.handleChange} />
                        Annual temperature range</span><br />

                        <span title="coolq:  Mean temperature of coolest three months.">
                        <input type="checkbox" className="chkBxTemp" name="coolq"
                            checked={this.state.coolq}
                            onChange={this.handleChange} />
                        Temp - coldest quarter</span><br />

                        <span title="warmq:  Mean temperature of warmest three months.">
                        <input type="checkbox" className="chkBxTemp" name="warmq"
                            checked={this.state.warmq}
                            onChange={this.handleChange} />
                        Temp - warmest quarter</span><br />

                        <span title="twetq:  Mean temperature of wettest three months.">
                        <input type="checkbox" className="chkBxTemp" name="twetq"
                            checked={this.state.twetq}
                            onChange={this.handleChange} />
                        Temp - wettest quarter</span><br />

                        <span title="twetq:  Mean temperature of driest three months">
                        <input type="checkbox" className="chkBxTemp" name="tdryq"
                            checked={this.state.tdryq}
                            onChange={this.handleChange} />
                        Temp - driest quarter</span>
                    </div>
                    <div>
                        <span title="avrf:  Mean annual rainfall.">
                        <input type="checkbox" className="chkBxRain" name="avrf"
                            checked={this.state.avrf}
                            onChange={this.handleChange} />
                        Mean annual rainfall</span><br />

                        <span title="wetmth:  Mean rainfall for wettest month.">
                        <input type="checkbox" className="chkBxRain" name="wetmth"
                            checked={this.state.wetmth}
                            onChange={this.handleChange} />
                        Rainfall - wettest month</span><br />

                        <span title="drymth:  Mean rainfall for driest month.">
                        <input type="checkbox" className="chkBxRain" name="drymth"
                            checked={this.state.drymth}
                            onChange={this.handleChange} />
                        Rainfall - driest month</span><br />

                        <span title="coefvar:  Standard deviation of the monthly mean temperatures divided by the mean monthly temperatures.">
                        <input type="checkbox" className="chkBxRain" name="coefvar"
                            checked={this.state.coefvar}
                            onChange={this.handleChange} />
                        Coefficent of variation - rain</span><br />

                        <span title="wetq:  Mean total rainfall for wettest three months.">
                        <input type="checkbox" className="chkBxRain" name="wetq"
                            checked={this.state.wetq}
                            onChange={this.handleChange} />
                        Rainfall - wettest quarter</span><br />

                        <span title="dryq:  Mean total rainfall for driest three months.">
                        <input type="checkbox" className="chkBxRain" name="dryq"
                            checked={this.state.dryq}
                            onChange={this.handleChange} />
                        Rainfall - driest quarter</span><br />

                        <span title="rfcoolq:  Mean total rainfall for coolest three months.">
                        <input type="checkbox" className="chkBxRain" name="rfcoolq"
                            checked={this.state.rfcoolq}
                            onChange={this.handleChange} />
                        Rainfall - coolest quarter</span><br />

                        <span title="rfhotq:  Mean total rainfall for warmest three months.">
                        <input type="checkbox" className="chkBxRain" name="rfhotq"
                            checked={this.state.rfhotq}
                            onChange={this.handleChange} />
                        Rainfall - warmest quarter</span>
                    </div>
                    <hr />
                </div>
            </form>
            <input type="button" className="message-OK" value="OK"
                onClick={() => this.props.onClose(this.state)} />
            <input type="button" className="message-cancel" value="Cancel"
                onClick={() => this.props.onClose(null)} />
        </div>);
    } // end render
}

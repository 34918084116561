import React, { Component } from 'react';
import { Map, TileLayer, LayersControl, ZoomControl, GeoJSON, Popup } from 'react-leaflet';
import L, { CircleMarker } from 'leaflet';
// npm complains about EditControl not being used... but it needs to be here.
import { EditControl } from 'react-leaflet-draw';
import Control from 'react-leaflet-control';
import pointsWithinPolygon from '@turf/points-within-polygon';
import { cloneFeatures, distance, countSelected, globalArea } from './utils';
import { ALGORITHM_NAMES } from './algorithms';
import './MapController.css';

const DEFAULT_POINT_RADIUS = 1.5;
const RELATIVE_SCORE_SCALE_FACTOR = 25.;
const GREY = 'rgb(180, 180, 180)';

const RESULT_COLORS = [
    'rgb(44, 74, 220)',
    'rgb(43, 219, 184)',
    'rgb(51, 164, 68)',
    'rgb(140, 234, 55)',
    'rgb(215, 239, 75)',
    'rgb(243, 239, 73)',
    'rgb(242, 207, 66)',
    'rgb(250, 150, 58)',
    'rgb(225, 51, 47)',
    'rgb(205, 86, 86)',
    'rgb(153, 77, 68)'];

const DRAW_OPTIONS = {
    shapeOptions: {
        stroke: true,
        color: '#3388ff',
        weight: 4,
        opacity: 0.5,
        fill: true,
        fillColor: null, //same as color by default
        fillOpacity: 0.2,
        showArea: true,
        clickable: true
    }
};

const RELATIVE_SCORES = [.25, .5, .75, 1.];



/**
 * Format a number with commas.
 */
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}



/**
 * Update a set of features.
 */
function updateFeatures(existingFeatures, newFeatures, adding) {
    let ni = new Set(newFeatures.map(f => f.properties.index));
    if(adding) {
        existingFeatures.features.forEach(f => f.properties.selected =
            f.properties.selected || ni.has(f.properties.index))
    } else {
        // we are deselecting features
        existingFeatures.features.forEach(f =>
            f.properties.selected = f.properties.selected && !ni.has(f.properties.index))
    }

}



/**
 * Add a point to a the data layer (target or result).
 */
function dataPointGeneratorGenerator(hasMatch, usedPoints, locPoints) {
    // note the use of x === false, which we need to distinguish between null
    // and false

    let myRenderer = L.canvas({ padding: 0.} );

    if(hasMatch) {
        // then we only draw the unused points (the others are dealth with later).
        return (geoJsonPoint, latlng) => {
            if(!geoJsonPoint.properties.score) {
                return new CircleMarker(latlng, {
                    renderer: myRenderer,
                    radius: DEFAULT_POINT_RADIUS,
                    color: GREY,
                    fillOpacity: 1.0
                });
            }
        }
    }

    if(locPoints) {
        return (geoJsonPoint, latlng) => {
            if(geoJsonPoint.properties.selected === null) {
                return new CircleMarker(latlng, {
                    renderer: myRenderer,
                    radius: DEFAULT_POINT_RADIUS,
                    color: 'green',
                    fillOpacity: 1.0
                });
            }
        }
    }

    if(usedPoints) {
        return (geoJsonPoint, latlng) => {
            if(geoJsonPoint.properties.selected) {
                return new CircleMarker(latlng, {
                    renderer: myRenderer,
                    radius: DEFAULT_POINT_RADIUS,
                    color: 'red',
                    fillOpacity: 1.0
                });
            }
        }
    }

    return (geoJsonPoint, latlng) => {
        if(geoJsonPoint.properties.selected === false) {
            return new CircleMarker(latlng, {
                renderer: myRenderer,
                radius: DEFAULT_POINT_RADIUS,
                color: 'blue',
                fillOpacity: 1.0
            });
        }
    }
}



/**
 * Add a point to a the target result layer.
 */
function targetResultPointGenerator() {

    let myRenderer = L.canvas({ padding: 0.5} );

    return (geoJsonPoint, latlng) => new CircleMarker(latlng, {
        renderer: myRenderer,
        radius: DEFAULT_POINT_RADIUS,
        color: RESULT_COLORS[geoJsonPoint.properties.score],
        fillOpacity: 1.0
    });
}



/**
 * Add a point to a the source result layer.
 */
function sourceResultPointGenerator(usedPoints) {

    let myRenderer = L.canvas({ padding: 0.5} );
    let color = usedPoints ? 'red' : 'blue';
    let comp = usedPoints ? (s => s > 0) : (s => s <= 0);

    return (geoJsonPoint, latlng) => {
        if(comp(geoJsonPoint.properties.score)) {
            return new CircleMarker(latlng, {
                renderer: myRenderer,
                radius: Math.max(
                    RELATIVE_SCORE_SCALE_FACTOR * geoJsonPoint.properties.relativeScore,
                    DEFAULT_POINT_RADIUS),
                fillOpacity: 1.0,
                color: color
            });
        }
    };
}



/**
 * Score for match popup.
 */
function resultCountPopup(f, layer) {
    if(f.properties.score > 0) {
        layer.bindPopup('# Matches: ' + f.properties.score);
        layer.on('mouseover', e => layer.openPopup());
        layer.on('mouseout', e => layer.closePopup());
    }
}



class MapControllerControl {
    constructor(mapController, clazz, adding, name, eraseOnRightClick) {
        this.mapController = mapController;
        this.clazz = clazz;
        this.adding = adding;
        this.name = name;
        this.eraseOnRightClick = eraseOnRightClick;
        this.activate = this.activate.bind(this);
        this.deactivate = this.deactivate.bind(this);
    }

    activate(after) {
        this.mapController._activateSelectTool(
            map => new this.clazz(map.map.leafletElement, DRAW_OPTIONS),
            this.adding, this.name, this.eraseOnRightClick);
        after && after();
    }

    deactivate(after) {
        this.mapController.deactivateSelectTool();
        after && after();
    }
}



/**
 * Class for controlling a map.
 */
export class MapController extends Component {
    constructor(props) {
        super(props);

        this.state = {
            thisUpdateMapHack: 0,
            visible: this.props.visible,
            features: null,
            resultFeatures: null,
            legendStrings: null,
            nSelectedFeatures: 0,
            selectedArea: 0.,
            currentPos: null
        };

        this.control = null;
        this.controlName = '';

        // react refs
        this.map = null;
        this.resultFeatureLayer = null;

        // functions used for controlling a map
        this.checkCanRun = this.checkCanRun.bind(this);
        this.sourceOrTarget = this.sourceOrTarget.bind(this);
        this.setFeatures = this.setFeatures.bind(this);
        this.clearResults = this.clearResults.bind(this);
        this.getFeatures = this.getFeatures.bind(this);
        this.getNSelectedFeatures = this.getNSelectedFeatures.bind(this);
        this.getSelectedArea = this.getSelectedArea.bind(this);
        this.getResultFeatures = this.getResultFeatures.bind(this);
        this.getMap = this.getMap.bind(this);
        this.setResults = this.setResults.bind(this);
        this.deactivateSelectTool = this.deactivateSelectTool.bind(this);
        this.handleClick = this.handleClick.bind(this);

        // private function bindins
        this._activateSelectTool = this._activateSelectTool.bind(this);
        this._stuffSelected = this._stuffSelected.bind(this);
        this._updateState = this._updateState.bind(this);
        this._doNearest = this._doNearest.bind(this);

        // controls
        this.rect = new MapControllerControl(this, L.Draw.Rectangle, true, 'rect', true);
        this.poly = new MapControllerControl(this, L.Draw.Polygon, true, 'poly', false);
        this.eraser = new MapControllerControl(this, L.Draw.Rectangle, false, 'eraser', true);
    }



    handleClick(e){
        this.setState({ currentPos: e.latlng });
        e.originalEvent.preventDefault();
    }



    /*
     * Selection controls.
     */
    checkCanRun() {
        if(this.state.resultFeatures !== null) {
            alert('please clear current match');
            return false;
        }
        return true;
    }



    sourceOrTarget() {
        return this.props.sourceOrTarget;
    }



    /**
     * Deselect all stations.
     */
    setFeatures(features, allSelected=false) {
        if(!this.checkCanRun())
            return;

        if(!features) {
            features = cloneFeatures(this.state.features);
            features.features = features.features
                .filter(f => f.properties.selected !== null);
        }

        if(allSelected !== null)
            features.features.forEach(f => f.properties.selected = allSelected);

        let nSelectedFeatures = allSelected === null ?
            countSelected(features) :
                allSelected ? features.features.length : 0

        let selectedArea = (allSelected === null || allSelected) ?
            globalArea(features) : 0.;

        this._updateState({
            thisUpdateMapHack: 1 - this.state.thisUpdateMapHack,
            resultFeatures: null,
            nSelectedFeatures: nSelectedFeatures,
            selectedArea: selectedArea,
            features: features
        });
    }



    clearResults() {
        this._updateState({
            thisUpdateMapHack: 1 - this.state.thisUpdateMapHack,
            resultFeatures: null
        });
    }



    getFeatures() {
        return this.state.features;
    }



    getNSelectedFeatures() {
        return this.state.nSelectedFeatures;
    }



    getSelectedArea() {
        return this.state.selectedArea;
    }



    getResultFeatures() {
        return this.state.resultFeatures;
    }



    getMap() {
        return this.map;
    }



    setResults(results, legendStringsOrMaxMatches) {
        let legendStrings = legendStringsOrMaxMatches;
        if(this.props.sourceOrTarget === 'source') {
            let splits = RELATIVE_SCORES.map(v => legendStringsOrMaxMatches * v);
            legendStrings = splits.map((s, i) => Math.round(s).toString())
        }

        this._updateState({
            resultFeatures : results,
            legendStrings: legendStrings,
            thisUpdateMapHack: 1 - this.state.thisUpdateMapHack
        });
    }



    /**
     * Activate a selection tool.
     *
     * @param controlMaker Callable that will be passed an instance of a leaflet
     *        map and should return a control
     *
     * @param adding Boolean for adding (*true*) or removing (*false*) points
     *        using the tool.
     *
     * If there is already an active control, it is deactivated.
     *
     * @note The control is deactivated after a single use. This control is
     * coupled to the implmentation of deactivateSelectTool.
     */
    _activateSelectTool(controlMaker, adding, name, eraseOnRightClick) {
        if(!this.checkCanRun())
            return;

        // remember that this.controlName was reset by deactivateSelectTool, so
        // save it first.
        let cn = this.controlName;
        if(this.deactivateSelectTool() && name === cn) {
            // then a control was deactivated
            return;
        }
        this.controlName = name;

        if(this.state.resultFeatures)
            this.setFeatures(this.state.features);

        if(this.map) {
            this.control = controlMaker(this);
            this.control.enable();
            // TODO: It would be great to...
            // be able to schedule a call to deactivateSelectTool here, but
            // since we don't know what event a tool will respond to or how it
            // arranges it, it is not obvious to me how one might do that.
            if(!this.control.ownAction) {
                this.map.leafletElement.on('draw:created', e => this._stuffSelected(e, adding));
                if(eraseOnRightClick) {
                    this.map.leafletElement.off('contextmenu');
                    this.map.leafletElement.on('contextmenu', e => this._stuffSelected(e, adding));
                }
            }
        }

        this._updateState({ currentPos: null });
    }



    /**
     * Deactivate a select tool.
     *
     * @return true if a control was deactivated and false otherwise.
     */
    deactivateSelectTool(localCall=false) {
        if(!this.checkCanRun())
            return;

        if(this.map) {
            this.map.leafletElement.off('draw:created');
            this.map.leafletElement.off('contextmenu');
            this.map.leafletElement.on('contextmenu', this.handleClick);
        }

        if(this.control) {
            this.control.disable();
            this.control = null;
            this.controlName = '';
            if(localCall) this.props.onControlDeactivated();
            return true;
        }

        return false;
    }



    /**
     * Called when either the rectangle or polygon select tools have been called
     * to add the features to the selected features.
     */
    _stuffSelected(ev, adding) {
        if(!this.checkCanRun())
            return;

        if(ev.type === 'contextmenu') {
            this._doNearest(ev, adding);
        } else if(this.state.features) {
            let newlySelected = pointsWithinPolygon(
                this.state.features,
                ev.layer.toGeoJSON())

            if(newlySelected) {
                let allFeatures = cloneFeatures(this.state.features);

                updateFeatures(
                    allFeatures,
                    newlySelected.features,
                    adding);

                this._updateState({
                    features: allFeatures,
                    nSelectedFeatures: countSelected(allFeatures),
                    selectedArea: globalArea(allFeatures),
                    thisUpdateMapHack: 1 - this.state.thisUpdateMapHack
                });
            }
        }

        this.deactivateSelectTool(true);
    }



    /**
     * Called when the nearest point select tool has been called to add the
     * feature to the selected features.
     */
    _doNearest(ev, adding) {
        if(!this.checkCanRun())
            return;

        if(this.state.features) {
            let closestIndex = null;
            let closestDistance = Infinity;
            let lat = ev.latlng.lat, lon = ev.latlng.lng;

            const checker = adding ?
                f => !f.properties.selected :
                f =>  f.properties.selected;

            this.state.features.features.forEach((f, i) => {
                let dist = distance(
                    lat,
                    lon,
                    f.geometry.coordinates[1], f.geometry.coordinates[0]);

                if(dist < closestDistance && checker(f)) {
                    closestIndex = i;
                    closestDistance = dist;
                }
            });

            if(closestIndex !== null && closestDistance < this.props.matchDistance) {
                let allFeatures = cloneFeatures(this.state.features);

                updateFeatures(
                    allFeatures,
                    [this.state.features.features[closestIndex]],
                    adding);

                this._updateState({
                    features: allFeatures,
                    nSelectedFeatures: countSelected(allFeatures),
                    selectedArea: globalArea(allFeatures),
                    thisUpdateMapHack: 1 - this.state.thisUpdateMapHack
                });
            }
        }

        this.deactivateSelectTool(true);
    }



    _updateState(newArgs) {
        this.setState({ ...this.state, ...newArgs });
    }



    componentDidMount() {
        this.map.leafletElement.on('contextmenu', this.handleClick);
    }
    componentDidUpdate() {
        // ensure that the layers are displayed in the right order
        if(this.resultFeatureLayer)
            this.resultFeatureLayer.leafletElement.bringToFront();
    }



    render() {
        const haveResults = this.state.resultFeatures !== null;
        return (
            <div
                style={{
                    visibility: (this.props.visible ? 'visible' : 'hidden'),
                    position: 'absolute',
                    width: '100%'
                }}
            >
                <Map
                    ref={m => this.map = m}
                    preferCanvas={true}
                    center={this.props.startCenter}
                    zoom={this.props.startZoom}
                    zoomControl={false}
                    dragging={true}
                    boxZoom={true}
                    doubleClickZoom={true}
                    scrollWheelZoom={true}
                    touchZoom={true}
                >

                {this.state.currentPos && (
                    <Popup
                        position={this.state.currentPos}
                        onClose={() => this._updateState({ currentPos: null })}
                    >
                        <h4>Current location</h4>
                        <table className="lat-lng-table"><tbody>
                            <tr><td>latitude:</td><td>{this.state.currentPos.lat}</td></tr>
                            <tr><td>longitude:</td><td>{this.state.currentPos.lng}</td></tr>
                        </tbody></table>
                    </Popup>
                )}

                {/* The base layers
                 find more layers at (for example)
                 https://leaflet-extras.github.io/leaflet-providers/preview/ */}
                <LayersControl position="topright">
                    <LayersControl.BaseLayer name="ESRI World Street Map" checked>
                        <TileLayer
                            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="OpenStreetMap">
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="ESRI World Map">
                        <TileLayer
                            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                        />
                    </LayersControl.BaseLayer>
                </LayersControl>

                <ZoomControl position='topright' />

                <Control position="topleft">{this.props.children}</Control>

                {/* we use two target layers to make sure the red dots are on
                    top of the other points. Another way to do this is put them
                    on different panes, but that causes problem when saving as
                    an image */}

                {/* The point layer (used loc file locations) */}
                {this.state.features && !haveResults && <GeoJSON
                    key={5*this.state.thisUpdateMapHack}
                    data={this.state.features}
                    pointToLayer={dataPointGeneratorGenerator(false, false, true)} />}

                {/* The point layer (unused) */}
                {this.state.features && <GeoJSON
                    key={5*this.state.thisUpdateMapHack + 1}
                    data={this.state.features}
                    pointToLayer={dataPointGeneratorGenerator(haveResults)} />}

                {/* The point layer (used) */}
                {this.state.features && <GeoJSON
                    key={5*this.state.thisUpdateMapHack + 2}
                    data={this.state.features}
                    pointToLayer={dataPointGeneratorGenerator(haveResults, true)} />}

                {/* we use two results layers to make sure the red dots are on
                    top of the other points. Another way to do this is put them
                    on different panes, but that causes problem when saving as
                    an image */}
                {/* The result layer (unused) */}
                {this.state.resultFeatures && <GeoJSON
                    key={5*this.state.thisUpdateMapHack + 3}
                    ref={l => {if(this.props.sourceOrTarget === 'target') this.resultFeatureLayer = l}}
                    data={this.state.resultFeatures}
                    pointToLayer={this.props.sourceOrTarget === 'target' ?
                        targetResultPointGenerator() : sourceResultPointGenerator(false)} />}

                {/* The result layer (used) */}
                {(this.state.resultFeatures && this.props.sourceOrTarget === 'source') && <GeoJSON
                    key={5*this.state.thisUpdateMapHack + 4}
                    ref={l => this.resultFeatureLayer = l}
                    data={this.state.resultFeatures}
                    onEachFeature={resultCountPopup}
                    pointToLayer={this.props.sourceOrTarget === 'target' ?
                        targetResultPointGenerator() : sourceResultPointGenerator(true)} />}

                {/* current state information */}
                <Control position="bottomright">
                    <table className={"legend-table"}><tbody>
                        {this.props.matchSettings && this.props.matchSettings.species &&
                            <tr><td>Species: {this.props.matchSettings.species}</td></tr>}
                        {this.props.matchSettings && this.props.matchSettings.algorithm &&
                            <tr><td>Algorithm: {ALGORITHM_NAMES[this.props.matchSettings.algorithm]}</td></tr>}
                        <tr><td>{this.props.sourceOrTarget === 'source' ?
                            this.state.nSelectedFeatures :
                            this.props.nSelectedFeaturesInOther} source features selected</td></tr>
                        <tr><td>{this.props.sourceOrTarget === 'target' ?
                            this.state.nSelectedFeatures :
                            this.props.nSelectedFeaturesInOther} target features selected</td></tr>
                        <tr><td>Approximate selected area: {numberWithCommas(this.state.selectedArea)} km<sup>2</sup></td></tr>
                    </tbody></table>
                </Control>

                {/* Legend */}
                <Control position="bottomleft">
                    <table className={"legend-table"}><tbody>

                        { /* legend header for target map */}
                        {this.state.resultFeatures && this.props.sourceOrTarget === 'target' && (<tr>
                            <th>Score</th>
                            <th>Color</th>
                            {(this.state.legendStrings ) && <th>Count</th>}
                        </tr>)}

                        { /* legend header for source map */}
                        {this.state.resultFeatures && this.props.sourceOrTarget === 'source' && (<tr>
                            <th>Symbol</th>
                            <th># matches</th>
                        </tr>)}



                        { /* legend lines for both target and source maps */}
                        {!this.state.resultFeatures && (<React.Fragment>
                        <tr key="0">
                            <td key="0"><div style={{
                                width: (5*DEFAULT_POINT_RADIUS).toString() + 'px',
                                height: (5*DEFAULT_POINT_RADIUS).toString() + 'px',
                                backgroundColor: 'red',

                            }}></div></td>
                            <td key="1">Selected stations</td>
                        </tr>

                        <tr key="1">
                            <td key="0"><div style={{
                                width: (5*DEFAULT_POINT_RADIUS).toString() + 'px',
                                height: (5*DEFAULT_POINT_RADIUS).toString() + 'px',
                                backgroundColor: 'blue',
                            }}></div></td>
                            <td key="1">Unselected stations</td>
                        </tr>
                        </React.Fragment>)}


                        { /* legend lines specific to target map */}
                        {(this.state.resultFeatures && this.props.sourceOrTarget === 'target') &&
                        RESULT_COLORS.map((c, i) => (<tr key={i}>
                            <td key="0">{i}</td>
                            <td key="1">
                                <div style={{
                                    width: (5*DEFAULT_POINT_RADIUS).toString() + 'px',
                                    height: (5*DEFAULT_POINT_RADIUS).toString() + 'px',
                                    backgroundColor: c,
                                }}></div>
                            </td>
                            <td key="2">{this.state.legendStrings[i]}</td>
                        </tr>))}



                        { /* legend lines specific to source map */}
                        {(!this.state.resultFeatures && this.props.sourceOrTarget === 'source') &&
                            <tr key="1">
                                <td key="0"><div style={{
                                    width: (5*DEFAULT_POINT_RADIUS).toString() + 'px',
                                    height: (5*DEFAULT_POINT_RADIUS).toString() + 'px',
                                    backgroundColor: 'green',
                                }}></div></td>
                                <td key="1">Specified lat/long location</td>
                            </tr>
                        }

                        {(this.state.resultFeatures && this.props.sourceOrTarget === 'source') &&
                            [<tr key="0" className="source-result">
                                <td key="0"><div style={{
                                    width: (5*DEFAULT_POINT_RADIUS).toString() + 'px',
                                    height: (5*DEFAULT_POINT_RADIUS).toString() + 'px',
                                    backgroundColor: GREY,
                                }}></div></td>
                                <td key="1">Unselected stations</td>
                            </tr>,

                            <tr key="1" className="source-result">
                                <td key="0"><div style={{
                                    width: (5*DEFAULT_POINT_RADIUS).toString() + 'px',
                                    height: (5*DEFAULT_POINT_RADIUS).toString() + 'px',
                                    backgroundColor: 'blue',
                                }}></div></td>
                                <td key="1">0</td>
                            </tr>,

                            <tr key="2" className="source-result">
                                <td key="0"><div style={{
                                    width: (5*DEFAULT_POINT_RADIUS).toString() + 'px',
                                    height: (5*DEFAULT_POINT_RADIUS).toString() + 'px',
                                    backgroundColor: 'red',
                                }}></div></td>
                                <td key="1">1</td>
                            </tr>].concat(
                                RELATIVE_SCORES.map((s, i) => <tr key={i+3} className="source-result">
                                    <td key="0"><div
                                        style={{
                                            width: (2*RELATIVE_SCORE_SCALE_FACTOR*s).toString() + 'px',
                                            height: (2*RELATIVE_SCORE_SCALE_FACTOR*s).toString() + 'px',
                                            backgroundColor: 'red',
                                            borderColor: 'red'
                                    }}></div></td>
                                    <td key="1">{this.state.legendStrings[i]}</td>
                                </tr>)
                            )
                        }

                    </tbody></table>
                </Control>
            </Map>
        </div>);
    }
}

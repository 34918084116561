import React, { Component } from 'react';

export class ClmData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            keepExisting: false
        }
    }

    render() {
        return(<div className="message-holder upload" style={this.props.style}>
            <div className="popupHeading">File Upload</div>

            Select a .clm file to upload
            <input type="file" name="upload"
                onChange={e => this.setState({
                    file: e.target.files[0],
                    keepExisting: this.state.keepExisting})} />
            <hr/>

            <input type="checkbox" name="addStations"
                title="Check this box if you wish to keep any stations you have already selected"
                style={{fontSize :'60%'}}
                value={this.state.keepExisting}
                onChange={() => this.setState({
                    file: this.state.file,
                    keepExisting: !this.state.keepExisting})} />

            <span>Add to existing selection</span>
            <hr/>

            <input type="button" className="message-OK" value="Upload File"
                style={{float: 'left', cursor:'pointer'}}
                onClick={() => this.props.onClose(this.state)} />

            <input type="button" className="message-cancel" value="Cancel"
                onClick={() => this.props.onClose(null)} />
            <br/>
            <br/>

            <span style={{fontSize: '80%'}}>
            NOTE:  Loading a .clm file will clear any stations you have already selected.
               To override, select the above checkbox.
            </span>
        </div>);
    }
}

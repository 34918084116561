import React, { Component } from 'react';
import proj4 from 'proj4';
import { saveAs } from 'file-saver';
import { Saving } from './Saving';
import { ClmData } from './ClmData';
import { Nearest } from './Nearest';
import { LayerSelector } from './Selector';
import { MapController } from './MapController';
import { DEFAULT_STATE, MatchSettings } from './MatchSettings';
import { VARIABLES, runMatch } from './algorithms';
import { cloneFeatures, distance } from './utils';



const DATASET_DIR = '/data';
const CONFIG_URL = '/config/config.json';
const TARGET_START_POINTS_JSON = 'Fishnet_20k_albers_aus_current.json';
const TARGET_START_ZOOM = 5;
const TARGET_CENTER = [-28., 134.];
const SOURCE_START_POINTS_JSON = 'samples_world_current.json';
const SOURCE_START_ZOOM = 2;
const SOURCE_CENTER = [20., 0.];
const OPEN_LAYERS_PROJ = 'EPSG:4326';



function Spinner(props) {
    return <div className="busy-div" style={{display: props.visible ? 'block' : 'none'}}></div>;
}



class ControllerControl {
    constructor(controlName, a, d, canRunWithResults=false) {
        this.name = controlName;
        this.activate = a;
        this.deactivate = d;
        this.canRunWithResults = canRunWithResults;
    }
}



class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeDataTab: 'source',
            loadDataFor: null,
            lodingclmOrll: null,
            busy: false,
            updateMapHack: 0,
            targetDataset: null,
            haveResults: false,
            targetScores: null,
            matchDistance: 50,
            currentTargetDatasetName: TARGET_START_POINTS_JSON,
            currentSourceDatasetName: SOURCE_START_POINTS_JSON,
            currentControl: null,
            controlIsUpdating: false,
            datasets: null,
            targetDatasets: null,
            sourceDatasets: null
        }

        this.matchSettings = { ...DEFAULT_STATE };
        this.convertURL = null;
        this._fetchTargetToken = { dismissed: false };
        this._fetchSourceToken = { dismissed: false };

        // react refs
        this.sourceMapController = null;
        this.targetMapController = null;

        // private function bindings
        this._updateWindowDimensions = this._updateWindowDimensions.bind(this);
        this._updateState = this._updateState.bind(this);
        this._handleDatasetSelected = this._handleDatasetSelected.bind(this);
        this._handleLoadFileChosen = this._handleLoadFileChosen.bind(this);
        this._handleTabToggled = this._handleTabToggled.bind(this);
        this._handleControlClicked = this._handleControlClicked.bind(this);

        this.checkCanRun = this.checkCanRun.bind(this);
        this.runMatch = this.runMatch.bind(this);
        this.clearMatch = this.clearMatch.bind(this);
        this.showLoadOptions = this.showLoadOptions.bind(this);
        this.hideLoadOptions = this.hideLoadOptions.bind(this);
        this.deactivateCurrentControl = this.deactivateCurrentControl.bind(this);

        this.saveFile = this.saveFile.bind(this);
        this.loadFile = this.loadFile.bind(this);

        // because of the way react-leaflet works, we need to update the style
        // elements (inserted by leaflet) with class "leaflet-container".  One
        // could often do this sort of thing by modifying the style of the
        // elements (using ReactDOM.findDOMNode(this)... along with timeout and
        // windows.requestAnimationFrame!!!), but this, in conjuction with the
        // prop 'updateCount' and the 'change the key to force an update idiom
        // is simpler and nicer. When I tried the other method, many tiles were
        // not rendered and I think this is to do with the size of the
        // container when the map is added... which is overcome by setting the
        // class appropriately in the first place.
        this.leafletContainerStyle = document.createElement('style');
        this.leafletContainerStyle.type = 'text/css';
        this.leafletContainerStyle.innerHTML = '';
        document.getElementsByTagName('head')[0].appendChild(this.leafletContainerStyle);
        this._updateWindowDimensions();

        // Importing file using environment variable
        import(`./${process.env.REACT_APP_FILE_PATH}.js`)
        .then(data => {            
                this._updateState({ 
                    datasets: data.DATASETS,
                    targetDatasets: data.TARGET_DATASETS,
                    sourceDatasets: data.SOURCE_DATASETS
                });
            }
        );
        
        this.matchSettingsControl = new ControllerControl(
            'setting',
            after => after && after(),
            after => after && after());

        this.sourceSaveControl = new ControllerControl(
            'save',
            after => after && after(),
            after => after && after(),
            true);
        this.sourceSaveControl.sourceOrTarget = 'source';

        this.targetSaveControl = new ControllerControl(
            'save',
            after => after && after(),
            after => after && after(),
            true);
        this.targetSaveControl.sourceOrTarget = 'target';

        this.sourcellLoadOptionsControl = new ControllerControl(
            'll',
            after => this.showLoadOptions('source', 'll'),
            this.hideLoadOptions);

        this.targetllLoadOptionsControl = new ControllerControl(
            'll',
            after => this.showLoadOptions('target', 'll'),
            this.hideLoadOptions);

        this.sourceClmLoadOptionsControl = new ControllerControl(
            'clm',
            after => this.showLoadOptions('source', 'clm'),
            this.hideLoadOptions);

        this.targetClmLoadOptionsControl = new ControllerControl(
            'clm',
            after => this.showLoadOptions('target', 'clm'),
            this.hideLoadOptions);

        this.sourceClearFeaturesControl = new ControllerControl(
            'clearall',
            after => this._updateState({ busy: true },
                () => setTimeout(
                    () => {
                        this.sourceMapController.setFeatures();
                        this._updateState({ busy: false },
                            () => {
                                this.deactivateCurrentControl();
                                after && after();
                            }
                        );
                    }
                )
            ),
            after => after && after());

        this.targetClearFeaturesControl = new ControllerControl(
            'clearall',
            after =>  this._updateState({ busy: true },
                () => setTimeout(
                    () => {
                        this.targetMapController.setFeatures();
                        this._updateState({ busy: false },
                            () => {
                                this.deactivateCurrentControl();
                                after && after();
                            }
                        );
                    }
                )
            ),
            after => after && after());

        this.runMatchControl = new ControllerControl(
            'matching',
            after => this._updateState({ busy: true },
                () => this.runMatch(
                    () => this._updateState({ busy: false },
                        () => {
                            this.deactivateCurrentControl();
                            after && after();
                        }
                    )
                )
            ),
            after => after && after());

        this.clearMatchControl = new ControllerControl(
            'matching',
            after => this._updateState({ busy: true },
                () => this.clearMatch(
                    () => this._updateState({ busy: false },
                        () => {
                            this.deactivateCurrentControl();
                            after && after();
                        }
                    )
                )
            ),
            after => after && after(),
            true);
    }



    checkCanRun() {
        if(this.state.haveResults) {
            alert('please clear current match');
            return false;
        }
        return true;
    }



    runMatch(after) {
        if(!this.matchSettings) {
            alert('please review match settings');
            return;
        }

        setTimeout(() => {
            try {
                let results = runMatch(
                    this.sourceMapController.getFeatures(),
                    this.targetMapController.getFeatures(),
                    this.matchSettings,
                    this.state.targetDataset.variances
                );

                if(results) {
                    let newState = {
                        updateMapHack: 1 - this.state.updateMapHack,
                        haveResults: true,
                        targetScores: results.scoreSummary
                    }

                    if(this.sourceMapController)
                        this.sourceMapController.setResults(
                            results.sources,
                            results.maxSourceMatches);

                    if(this.targetMapController)
                        this.targetMapController.setResults(
                            results.targets,
                            results.scoreSummary);

                    this._updateState(newState);
                }
            } catch(error) {
                alert(error.toString());
            } finally {
                after && after();
            }
        });
    }



    clearMatch(after) {
        this._updateState({ haveResults: false, scoreSummary: null }, () => setTimeout(() => {
            if(this.sourceMapController) this.sourceMapController.clearResults();
            if(this.targetMapController) this.targetMapController.clearResults();
            after && after();
        }));
    }



    saveFile(fileExtension, sourceOrTarget) {
        // fileExtension does not start with a dot

        if(fileExtension === null)
            return; // should never get here
        if(sourceOrTarget === null)
            return; // should never get here
        if(sourceOrTarget !== 'target' && sourceOrTarget !== 'source')
            return; // should never get here

        let mapController = sourceOrTarget === 'target' ?
            this.targetMapController : this.sourceMapController;

        if(fileExtension === 'clm') {
            // save a clm file

            let selectedArea = this.sourceMapController ?
                this.sourceMapController.getSelectedArea() : 0;
            let fileData = 'dataset\tworldclim\tarea\t' + selectedArea + '\r\n';
            fileData += 'ID\tloc1\tloc2\tloc3\tlat\tlon\t';
            VARIABLES.forEach(v => fileData += v + '\t');
            fileData = fileData.replace(/.$/, '\r\n');
            mapController.getFeatures().features.forEach(f => {
                if(f.properties.selected) {
                    let line = (f.properties.index || 'null') + '\tnull\tnull\tnull\t';
                    // assumes that this is a point... check for that?
                    line += f.geometry.coordinates[1].toString() + '\t'
                         +  f.geometry.coordinates[0].toString() + '\t';
                    VARIABLES.forEach(v => {
                        let val = f.properties[v];
                        if(val !== undefined) line += val.toString() + '\t';
                        else line += 'null\t';
                    });
                    fileData += line.replace(/.$/, '\r\n');
                }
            });
            saveAs(
                new Blob([fileData], {type: "text/plain;charset=utf-8"}),
                sourceOrTarget + '.clm');

        } else if(fileExtension === 'asc' || fileExtension === 'tif') {
            // save an ascii grid
            // should only get here if the following does not result in an error
            // or undefined (see bottom of render method where this f is called
            // from)
            const header = this.state.targetDataset.header;
            let data = Array(header.nrows * header.ncols);
            data.fill(-9999);

            // TODO: assert f.properties.realIndex < n
            this.targetMapController.getResultFeatures().features.forEach(
                f => data[f.properties.realIndex] = f.properties.score);

            let rs =
                'ncols        ' + header.ncols.toString() + '\r\n' +
                'nrows        ' + header.nrows.toString() + '\r\n' +
                'xllcorner    ' + header.xllcorner.toString() + '\r\n' +
                'yllcorner    ' + header.yllcorner.toString() + '\r\n' +
                'cellsize     ' + header.cellsize.toString() + '\r\n' +
                'NODATA_value -9999\r\n' +
                data.join(' ');

            if(fileExtension === 'asc') {
                saveAs(
                    new Blob([rs], {type: "text/plain;charset=utf-8"}),
                    sourceOrTarget + '.asc');
            } else {
                fetch(this.convertURL + '?proj=' + encodeURIComponent(
                    this.state.targetDataset.proj || '+proj=latlong'
                ),
                {
                    mode: 'cors',
                    method: 'POST',
                    headers: new Headers({ 'Content-Type': 'plain/text' }),
                    body: rs
                })
                    .then(r => r.blob())
                    .then(r => saveAs(r, sourceOrTarget + '.tif'))
                    .catch(() => alert('error savind dataset'));
            }
        } else if(fileExtension === 'scores') {
            if(this.state.targetScores !== null) {
                // save a csv file of the target scores
                let fileData = this.state.targetScores
                    .map((v, i) => i.toString() + ',' + v.toString())
                    .join('\r\n');
                saveAs(
                    new Blob([fileData], {type: "text/csv;charset=utf-8"}),
                    'score-summary.csv');
            }
        }
    }



    showLoadOptions(sourceOrTarget, clmOrll, after) {
        if(this.state.loadDataFor) {
            this.hideLoadOptions(after);
            return;
        }

        this._updateState({
            loadDataFor: sourceOrTarget,
            lodingclmOrll: clmOrll,
        }, after);
    }
    hideLoadOptions(after) {
        this._updateState({ loadDataFor: null, lodingclmOrll: null, }, after);
    }
    _handleLoadFileChosen(nearestDialogState) {
        if(nearestDialogState !== null) {
            if(nearestDialogState.file === null) {
                alert('no file chosen');
                return;
            }

            this.matchSettings.species = nearestDialogState.file.name.replace(/\.[^/.]+$/, "");

            const ke = nearestDialogState.keepExisting;
            this.loadFile(
                nearestDialogState.file,
                ke === null || ke === undefined || ke,
                this.state.loadDataFor,
                this.state.lodingclmOrll,
                nearestDialogState.matchDistance);
        }

        this._updateState({
            matchDistance: (nearestDialogState === null || !nearestDialogState.matchDistance) ?
                this.state.matchDistance : nearestDialogState.matchDistance
        }, this.deactivateCurrentControl);
    }

    /**
     * Load data from a clm file.
     */
    loadFile(dataFile, keepExisting, sourceOrTarget, clmOrll, matchDistance) {
        // TODO: check that the file has the right header.
        let handleContents = contents => {
            let mapController = sourceOrTarget === 'target' ?
                this.targetMapController : this.sourceMapController;

            if(mapController === null)
                return;

            let features;
            let lines = contents.split('\n')
                .map(l => l.replace(/#.*$/g, '')) // get rid of comments
                .filter(l => l.length > 0); // get rid of empty lines

            if(clmOrll === 'clm') {
                lines.shift(); // drop the line stating the area
                const fileHeader = lines.shift().split('\t').map(v => v.trim());
                features = lines.map(line => {
                    const bits = line.split('\t');
                    return {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [parseFloat(bits[5]), parseFloat(bits[4])]},
                        properties: Object.fromEntries(bits.slice(5).map((b, i) => [
                            fileHeader[i+5],
                            parseFloat(b)
                        ]))
                    }
                });

                const existingFeatures = keepExisting ?
                    cloneFeatures(mapController.getFeatures().features
                        .filter(f => f.properties.selected !== null)) : null;

                const offset = existingFeatures === null ?
                    0 : existingFeatures.length;

                features.forEach((f, i) => {
                    f.properties.index = i + offset;
                    f.properties.selected = true;
                });

                if(existingFeatures !== null)
                    features = existingFeatures.concat(features);

            } else if(clmOrll === 'll') {
                let lls = lines.map(line => line.split(/[\t, ]+/).map(v => parseFloat(v)))
                let closestDistance, closestIndex, dist;

                features = cloneFeatures(
                    mapController.getFeatures().features
                        .filter(f => f.properties.selected !== null));

                const offset = features.length;
                let newPoints = lls.map((ll, j) => {
                    closestDistance = Infinity;
                    closestIndex = -1;

                    features.forEach((f, i) => {
                        dist = distance(
                            ll[0],
                            ll[1],
                            f.geometry.coordinates[1],
                            f.geometry.coordinates[0]);

                        if(dist < closestDistance && !f.properties.selected) {
                            closestIndex = i;
                            closestDistance = dist;
                        }
                    });

                    if(closestDistance < matchDistance && closestIndex >= 0)
                        features[closestIndex].properties.selected = true;

                    return {
                        'type': 'Feature',
                        'properties': {
                            selected: null,
                            index: offset + j
                        },
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [ll[1], ll[0]]
                        }
                    };
                });

                features = features.concat(newPoints);

            } else {
                // something is wrong
            }

            mapController.setFeatures(
                {type: 'FeatureCollection', features: features},
                null);
        }

        let reader = new FileReader();
        reader.onload = r => handleContents(reader.result);
        reader.readAsText(dataFile);
    }



    _handleDatasetSelected(
        datasetName,
        mapController,
        previousDatasetName
    ) {
        if(!this.checkCanRun())
            return;

        if(mapController === null)
            return;

        // hold these in case we have an error somwhere strange and need to
        // set them back to their inital value
        const previousFeatures = mapController.getFeatures();
        const isTarget = mapController.sourceOrTarget() === 'target';
        let token = {dismissed: false};
        if(isTarget) {
            this._fetchTargetToken.dismissed = true;
            this._fetchTargetToken  = token;
        } else {
            this._fetchSourceToken.dismissed = true;
            this._fetchSourceToken = token;
        }

        
        // Importing file using environment variable
        fetch(DATASET_DIR + '/' + datasetName, { method: 'GET' })
            .then(response => response.json())
            .then(data => {
                if(token.dismissed)
                    // then there has been another request since, so do nothing
                    return;

                if(data.proj4) {
                    const projector = proj4(data.proj4, OPEN_LAYERS_PROJ);
                    data.features.forEach(f => {
                        f.geometry.coordinates = projector.forward(f.geometry.coordinates);
                        f.properties.selected = false;
                    });
                    delete data.proj4;
                }
                data.features.forEach((f, i) => f.properties.index = i);

                let newState = { updateMapHack: 1 - this.state.updateMapHack };
                if(isTarget) {
                    newState.targetDataset = this.state.datasets[datasetName];
                    newState.currentTargetDatasetName = datasetName;
                } else {
                    newState.currentSourceDatasetName = datasetName;
                }

                // ideally one would do this via props, but I had problems
                // cloning the features successfully... inexperience I guess.
                mapController.setFeatures(
                    data,
                    // if we are loading a target grid, we select all
                    isTarget && newState.targetDataset.header !== undefined);
                this._updateState(newState);

            }).catch(e => {
                if(token.dismissed)
                    // then there has been another request since, so do nothing
                    return;

                alert('Error fetching dataset.');

                if(
                    isTarget && (
                    this.state.currentTargetDatasetName !== previousDatasetName ||
                    mapController.getFeatures() !== previousFeatures)
                ) {
                    mapController.setFeatures(previousFeatures, null);
                    this._updateState({currentTargetDatasetName: previousDatasetName});
                } else if(
                    this.state.currentSourceDatasetName !== previousDatasetName ||
                    mapController.getFeatures() !== previousFeatures
                ) {
                    mapController.setFeatures(previousFeatures, null);
                    this._updateState({currentSourceDatasetName: previousDatasetName});
                }
    });   
    }



    _handleTabToggled(sourceOrTarget) {
        this._updateState({
            activeDataTab: sourceOrTarget,
            updateMapHack: 1 - this.state.updateMapHack
        }, this.deactivateCurrentControl);
    }



    /**
     * Update the size of the map.
     */
    _updateWindowDimensions() {
        this.leafletContainerStyle.innerHTML = '.leaflet-container { ' +
            'height: ' + window.innerHeight + 'px; ' +
            'width: 100%' +
            'margin: 0 auto;' +
        '}';
    }



    /**
     * Update state.
     */
    _updateState(newArgs, after) {
        this.setState({ ...this.state, ...newArgs }, after);
    }



    /*
     * Lifecyle methods. Used for making sure things layout nicely.
     */
    componentDidMount() {
        window.addEventListener('resize', this._updateWindowDimensions);
        this._handleDatasetSelected(
            this.state.currentSourceDatasetName,
            this.sourceMapController,
            this.state.currentSourceDatasetName);
        this._handleDatasetSelected(
            this.state.currentTargetDatasetName,
            this.targetMapController,
            this.state.currentTargetDatasetName);

        // get the url of the API
        fetch(CONFIG_URL, {method: 'GET'})
            .then(r => {
                if(r.status === 200) return r.json();
                else r.reject();
            })
            .then(t => this.convertURL = t.API_URL + '/ascii2gtiff')
            .catch(r => alert('failed to locate TIFF converter: TIFF export not available'));
    }
    componentDidUpdate() {
        this._updateWindowDimensions();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this._updateWindowDimensions);
    }



    _handleControlClicked(control) {
        // It isn't ideal that in this method, we:
        // - set the new Control,
        // - deactivate the old control,
        // - activate the new control.
        //
        // It would be better to:
        // - deactivate the old control,
        // - activate the new control.
        // - set the new Control,
        //
        // However, due to the fact that setState is async, It it hard to
        // ensure the right order of operations (specifically, calls to setState
        // may get 'run' out of order).
        //
        // I get around this (partially?) by using the second argument of
        // setState, which is guaranteed to be called after the state is updated.
        // This requires some methods (like runMatch) to accept callbacks and
        // use setTimout.
        //
        // I *think* the chains are complete in the current implementation, but
        // it would be easy to have 'nested' calls to setState creep in. To
        // keep things working properly, one would need the methods/functions
        // that make those calls accept callbacks and chain them through calls
        // to setState... ouch.
        //
        // Another approach (or possbily brain fart) might be to have methods:
        // - preActivate,
        // - postActivate,
        // - preDeactivate,
        // - postDeactivate,
        //
        // on controls... but this needs more thought.

        if(this.state.controlIsUpdating) {
            return;
        }

        this._updateState({ controlIsUpdating: true }, () => setTimeout(() => {
            try {
                let currentControl = this.state.currentControl;

                if(control === null) {
                    // leave nested for clarity
                    if(currentControl !== null) {
                        this._updateState({ currentControl: null }, currentControl.deactivate);
                    }
                    return;
                }

                if(!(control.canRunWithResults || this.checkCanRun()))
                    return;

                if(currentControl === null) {
                    this._updateState({ currentControl: control }, control.activate);
                } else if(currentControl === control) {
                    this._updateState({ currentControl: null }, control.deactivate);
                } else {
                    this._updateState({ currentControl: control }, () => {
                        currentControl.deactivate(control.activate);
                    });
                }
            } catch(error) {
                alert(error);
            } finally {
                this._updateState({ controlIsUpdating: false });
            }
        }));
    }
    deactivateCurrentControl() {
        this._handleControlClicked(null);
    }



    render() {
        return (
            <React.Fragment>
                <MapController
                    ref={mc => this.sourceMapController = mc}
                    visible={this.state.activeDataTab === 'source'}
                    startZoom={SOURCE_START_ZOOM}
                    startCenter={SOURCE_CENTER}
                    sourceOrTarget="source"
                    matchSettings={this.matchSettings}
                    nSelectedFeaturesInOther={this.targetMapController !== null ?
                        this.targetMapController.getNSelectedFeatures() : 0}
                    matchDistance={this.state.matchDistance}
                    parentUpdateMapHack={this.state.updateMapHack}
                    onControlDeactivated={this.deactivateCurrentControl}
                >

                    {this.sourceMapController && this.state.sourceDatasets && <LayerSelector
                        haveResults={this.state.haveResults}
                        availableDatasets={this.state.sourceDatasets}
                        selectedDataset={this.state.currentSourceDatasetName}
                        currentTab={this.state.activeDataTab}
                        activeControl={this.state.currentControl}
                        //controls
                        onControlClicked={this._handleControlClicked}
                        rect={this.sourceMapController.rect}
                        poly={this.sourceMapController.poly}
                        eraser={this.sourceMapController.eraser}
                        clearFeatures={this.sourceClearFeaturesControl}
                        runMatch={this.runMatchControl}
                        clearMatch={this.clearMatchControl}
                        tabToggle={this._handleTabToggled}
                        matchSettings={this.matchSettingsControl}
                        llLoadOptions={this.sourcellLoadOptionsControl}
                        clmLoadOptions={this.sourceClmLoadOptionsControl}
                        saveOptions={this.sourceSaveControl}
                        onDatasetSelected={(ds, previousDatasetName) =>
                            this._handleDatasetSelected(
                                ds,
                                this.sourceMapController,
                                previousDatasetName)}
                    />}
                </MapController>
                <MapController
                    ref={mc => this.targetMapController = mc}
                    visible={this.state.activeDataTab === 'target'}
                    startZoom={TARGET_START_ZOOM}
                    startCenter={TARGET_CENTER}
                    sourceOrTarget="target"
                    matchSettings={this.matchSettings}
                    nSelectedFeaturesInOther={this.sourceMapController !== null ?
                        this.sourceMapController.getNSelectedFeatures() : 0}
                    matchDistance={this.state.matchDistance}
                    parentUpdateMapHack={this.state.updateMapHack}
                    onControlDeactivated={this.deactivateCurrentControl}
                 >
                    {this.targetMapController && this.state.targetDatasets && <LayerSelector
                        haveResults={this.state.haveResults}
                        availableDatasets={this.state.targetDatasets}
                        selectedDataset={this.state.currentTargetDatasetName}
                        currentTab={this.state.activeDataTab}
                        activeControl={this.state.currentControl}
                        //controls
                        onControlClicked={this._handleControlClicked}
                        rect={this.targetMapController.rect}
                        poly={this.targetMapController.poly}
                        eraser={this.targetMapController.eraser}
                        clearFeatures={this.targetClearFeaturesControl}
                        runMatch={this.runMatchControl}
                        clearMatch={this.clearMatchControl}
                        tabToggle={this._handleTabToggled}
                        matchSettings={this.matchSettingsControl}
                        llLoadOptions={this.targetllLoadOptionsControl}
                        clmLoadOptions={this.targetClmLoadOptionsControl}
                        saveOptions={this.targetSaveControl}
                        onDatasetSelected={(ds, previousDatasetName) =>
                            this._handleDatasetSelected(
                                ds,
                                this.targetMapController,
                                previousDatasetName)}
                    />}
                </MapController>

                { /* div for match settings popup */ }
                {this.matchSettingsControl === this.state.currentControl ? (
                    <div className="alert-div" style={{zIndex: 999}}>
                        <MatchSettings
                            onClose={s => {
                                if(s) this.matchSettings = { ...s };
                                this.deactivateCurrentControl();
                            }}
                            style={{zIndex: 1000}}
                            initialState={this.matchSettings}
                        />
                    </div>) : null
                }

                { /* div for save popup */ }
                {(
                    this.state.currentControl === this.sourceSaveControl ||
                    this.state.currentControl === this.targetSaveControl) && (
                    <div className="alert-div" style={{zIndex: 999}}>
                        <Saving
                            onClose={s => {
                                if(s !== null)
                                    this.saveFile(
                                        s,
                                        this.state.currentControl.sourceOrTarget);
                                this.deactivateCurrentControl();
                            }}
                            style={{zIndex: 1000}}
                            canTiff={this.convertURL}
                            isGrid={
                                this.state.activeDataTab === 'target' &&
                                this.state.targetDataset !== null &&
                                this.state.targetDataset.header !== undefined &&
                                this.targetMapController !== null &&
                                this.targetMapController.getResultFeatures() !== null}
                            haveScores={
                                this.state.activeDataTab === 'target' &&
                                this.state.targetScores !== null}
                        />
                    </div>)
                }

                { /* div for load clm popup */ }
                {(this.state.loadDataFor !== null && this.state.lodingclmOrll === 'clm') ? (
                    <div className="alert-div" style={{zIndex: 999}}>
                        <ClmData
                            onClose={this._handleLoadFileChosen}
                            style={{zIndex: 1000}}
                        />
                    </div>) : null
                }

                { /* div for load lat/lng popup */ }
                {(this.state.loadDataFor !== null && this.state.lodingclmOrll === 'll') ? (
                    <div className="alert-div" style={{zIndex: 999}}>
                        <Nearest
                            onClose={this._handleLoadFileChosen}
                            matchDistance={this.state.matchDistance}
                            style={{zIndex: 1000}}
                        />
                    </div>) : null
                }

                { /* div for busy popup */ }
                <Spinner visible={this.state.busy} />
            </React.Fragment>
        );
    }
}

export default App;
import React, { Component } from 'react';

export class Nearest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            matchDistance: this.props.matchDistance
        }
        this._updateState = this._updateState.bind(this);
    }

    _updateState(newState) {
        this.setState({ ...this.state, ...newState });
    }

    render() {
        return (<div className="message-holder upload" style={this.props.style}>
            <div className="popupHeading">Upload a location file</div>

            Select a location file to upload
            <input type="file" name="upload"
                onChange={e => this._updateState({ file: e.target.files[0] })} />
            <hr/>

            <div id="match-distance">
                <span>Max Distance (km):</span>
                <input type="text" name="distance"
                    defaultValue={this.state.matchDistance}
                    onChange={ev => {
                        const v = ev.target.value;
                        if(v)
                            this._updateState({ matchDistance: parseFloat(v) });
                    }}
                />
            </div>
            <hr/>

            <input type="button" className="message-OK" value="Upload File"
                onClick={() => this.props.onClose(this.state)} />

            <input type="button" className="message-cancel" value="Cancel"
                onClick={() => this.props.onClose(null)} />
        </div>);
    }
}

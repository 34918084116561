export function cloneFeatures(f) {
    //return Array.from(Object.assign(Object.create(f), f));
    return JSON.parse(JSON.stringify(f));
}



// from
// https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
const p = 0.017453292519943295; // Math.PI / 180
const c = Math.cos;
export function distance(lat1, lon1, lat2, lon2) {
      return 12742. * Math.asin(Math.sqrt(
          0.5 - c((lat2 - lat1) * p)/2 +
          c(lat1 * p) * c(lat2 * p) *
          (1 - c((lon2 - lon1) * p))/2)); // 12742. = 2 * R; R = 6371 km
}



/**
 * Area of pixels.
 *
 * The combined area of cells in a 2x2 grid that contain at least one point.
 */
const CELL_AREAS = require('./cell_areas.json');
const CELL_AREAS_MISSING_VALUE = -9999;
export function globalArea(features) {
    let selected = new Set();
    features.features.forEach(f => {
        if(f.properties.selected) {
            let col = Math.max(0., Math.floor((180. + f.geometry.coordinates[0]) / 2.));
            let row = Math.max(0., Math.floor((90.  - f.geometry.coordinates[1]) / 2.));
            let index = row*180 + col;
            selected.add(index);
        }
    });
    let total = 0.;
    selected.forEach((index) => {
        let area = CELL_AREAS[index];
        if(area !== CELL_AREAS_MISSING_VALUE) total += area;
    });
    return Math.floor(total);
}



/**
 * Count the number of features that are selected.
 */
export function countSelected(features) {
    return features.features.reduce(
        (t, f) => t + (f.properties.selected ? 1 : 0), 0);
}

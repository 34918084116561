import React, { Component } from 'react';



const TOOL_DESCRIPTIONS = {
    "DEFAULT": "<strong>Tool tips</strong><br>" +
        "This box displays a description of the active tool.",
    "NEAR": "<strong>Station Selection</strong><br>" +
        "Upload a \"location\" file.",
    "POLY": "<strong>Station Selection</strong>: Polygon<br>" +
        "Click and drag to draw freehand shapes.",
    "RECT": "<strong>Station Selection</strong>: Rectangle or Point<br>" +
        "> Drag a rectangle to select a group of points.<br>" +
        "> Right click to select a single point.",
    "RECTERASE": "<strong>Station Deselect</strong>:<br>" +
        "> Draw a rectangle to deselect a group of points.<br>" +
        "> Right click to deselect a single point.",
    "CLEAR": "<strong>Station Deselect</strong><br>" +
        "Clear all currently-selected weather stations.",
    "RUNMATCH": "<strong>Run Match</strong><br>" +
        "Compare the source and target regions." +
        "Click the \"match settings\" button (immediately to left) to " +
        "specify algorithm and variables",
    "CLEARMATCH": "<strong>Clear Match</strong><br>" +
        "Clear the current match results and return to station-select mode.",
    "LOADCLM": "<strong>Load .clm File</strong><br>" +
        "Load a previously-defined group of weather stations into Climatch.",
    "SAVEMAP": "<strong>Save Map</strong><br>" +
        "Save the current map as an image file, or a text format.",
    "SETTINGS": "<strong>Match Settings</strong><br>" +
        "Specify an algorithm and a set of variables to be used in the match."
};






export class LayerSelector extends Component {
    constructor(props) {
        super(props);
        this.state = { toolDescription: null, collapsed: false, datasets: null };

        // public funcion bindings
        this.toolClassName = this.toolClassName.bind(this);
        this.genTab = this.genTab.bind(this);
        this.genTool = this.genTool.bind(this);

        // private function bindings
        this._toolPrompt = this._toolPrompt.bind(this);
        this._updateState = this._updateState.bind(this);

        // Importing file using environment variable
        import(`./${process.env.REACT_APP_FILE_PATH}.js`)
        .then(data => {            
            this._updateState({ datasets: data.DATASETS});
            }
        );
    }



    toolClassName(toolName) {
        return toolName + ((
            this.props.activeControl &&
            toolName === this.props.activeControl.name
        ) ? ' active' : '');
    }



    /**
     * Set the text describing the active (or moused over) tool.
     */
    _toolPrompt(toolName=null) {
        this._updateState({
            toolDescription: TOOL_DESCRIPTIONS[toolName || 'DEFAULT']
        });
    }



    _updateState(newState) {
        this.setState({ ...this.state, ...newState });
    }



    componentDidMount() {
        this._toolPrompt();
    }



    genTab(tors) {
        return (<input
            type="button"
            className={'region-toggle-tab' + (this.props.currentTab === tors ? ' active' : '')}
            value={(tors === 'source' ? 'Source' : 'Target') + ' Region'}
            onClick={e => this.props.tabToggle(tors)}
        />);

    }



    genTool(control, tipKey, value, other={}) {
        return (<li className={this.toolClassName(control.name)} key={control.name}>
            <input
                type='button'
                value={value}
                onClick={() => this.props.onControlClicked(control)}
                onMouseEnter={() => this._toolPrompt(tipKey)}
                onFocus={() => this._toolPrompt(tipKey)}
                onMouseLeave={() => this._toolPrompt()}
                {...other}
            />
        </li>);
    }



    render() {
        if(this.state.datasets === null){
            return <div>Loading</div>
        }        
        return (
            <div className="controls">
                <span className="region-toggle-spacer lft" >&nbsp;</span>
                {this.genTab('source')}
                {this.genTab('target')}
                <span className="region-toggle-spacer rght">&nbsp;</span>
                <div className="header"
                    onClick={e => this._updateState({ collapsed: !this.state.collapsed })}
                    onKeyPress={e => this._updateState({ collapsed: !this.state.collapsed })}
                >
                    <h4>Control Panel</h4>

                    {/* The 'collapse' toggle*/}
                    <img
                        src="images/collapse-arrow.png"
                        alt=""
                        tabIndex={0}
                        width="25" height="25"
                        {...this.state.collapsed ? {} : {style: {transform: 'rotate(90deg)'}}}
                    />
                </div>
                <div className="content" style={this.state.collapsed ? {display: 'none'} : {}}>
                    <div className="icon-box">
                        <p className="pane-title">Select Stations</p>
                        <ul className="icons">
                            {this.genTool(this.props.rect, 'RECT', 'Select with box')}
                            {this.genTool(this.props.poly, 'POLY', 'Freehand select')}
                            {this.genTool(this.props.llLoadOptions, 'NEAR', 'Select by location')}
                            {this.genTool(this.props.clmLoadOptions, 'LOADCLM', 'Load an existing .clm file')}
                        </ul>
                    </div>
                    <div className="icon-box">
                        <p className="pane-title">Deselect Stations</p>
                        <ul className="icons">
                            {this.genTool(this.props.eraser, 'RECTERASE', 'Deselect stations')}
                            {this.genTool(this.props.clearFeatures, 'CLEAR', 'Clear all selected stations')}
                        </ul>
                    </div>
                    <div className="select-box">
                        <p className="pane-title">Data Set</p>
                        <br/>
                        <select
                            defaultValue={this.props.selectedDataset || ''}
                            onChange={o => this.props.onDatasetSelected(o.target.value)}
                            {...(this.props.haveResults || (
                                this.props.activeControl &&
                                this.props.activeControl.name !== ''
                            )) ? {disabled: 'disabled'} : ''}
                        >
                            {this.props.availableDatasets.map(k =>
                                <option key={k} value={k}>{this.state.datasets[k].name}</option>)}
                        </select>
                    </div>
                    <div className="icon-box">
                        <p className="pane-title">Analysis</p>
                        <ul className="icons">
                            {(this.props.activeControl === this.props.clearMatch || this.props.haveResults) ?
                                this.genTool(this.props.clearMatch,'CLEARMATCH', 'Clear match', {id: 'clearmatch'}) :
                                this.genTool(this.props.runMatch, 'RUNMATCH', 'Run match', {id: 'runmatch'})}
                            {this.genTool(this.props.matchSettings, 'SETTINGS', 'Match settings')}
                            {this.genTool(this.props.saveOptions, 'SAVEMAP', 'Save map')}
                        </ul>
                    </div>

                    <a href="/climatch_manual.doc" download>Manual</a>

                    <div
                        className="selection-desc"
                        title="Text in this box describes the action of the active tool."
                        dangerouslySetInnerHTML={{__html: this.state.toolDescription}}
                    ></div>
                </div>
            </div>
        );
    }
}
import { cloneFeatures } from './utils';

/**
 * The names of the variables we are intersted in.
 *
 * @note This must be kept in sync with the variables:
 *       - in the state of MatchSettings,
 *       - the variables in the json files that contain the input data.
 *       - the array *VARIANCES* below.
 */
export const VARIABLES = require('./variables.json');



export const ALGORITHM_NAMES = {
    'euclidean': 'Euclidean',
    'closestStandardScore': 'Closest Standard Score'
};


/**
 * Variances loaded when the project was moved to an OPA. The variances.json
 * is created by the script db2geojson.py in the root of this repository.
 */
const NEW_VARIANCES = require('./variances_new.json');


/**
 * The variances of the variables defined in *VARIABLES* (calculated from the
 * worlld climate data... I think).
 */
const OLD_VARIANCES = require('./variances_old.json');


const VARIANCES = {
    orig: OLD_VARIANCES,
    updated: NEW_VARIANCES
};


const CUTS = [
    Math.pow(3.900, 2),
    Math.pow(1.645, 2),
    Math.pow(1.285, 2),
    Math.pow(1.004, 2),
    Math.pow(0.845, 2),
    Math.pow(0.675, 2),
    Math.pow(0.525, 2),
    Math.pow(0.385, 2),
    Math.pow(0.255, 2),
    Math.pow(0.125, 2)
];



/**
 * Subset one of the arrays defined above.
 */
function _getMatchVals(values, matchSettings){
    return values.filter((v, i) => matchSettings[VARIABLES[i]]);
}



/**
 * Subset the values for a point.
 */
function _extractMatchVars(matchVars, point) {
    return matchVars.map(v => point.properties[v]);
}



/**
 * Euclidian match algoithm.
 */
function euclidean(sourceVars, targetVars, variances) {
    let nvars = variances.length;
    let targetScores = Array(targetVars.length).fill(0);
    let sourceCounts = Array(sourceVars.length).fill(0);
    let scoreSummary = Array(11).fill(0);
    let dist, minDist, currMatch;

    for(let t=0; t<targetVars.length; ++t) {
        minDist = Infinity;
        currMatch = -1;

        for(let s=0; s<sourceVars.length; ++s) {
            dist = 0;

            for(let v=0; v<nvars; ++v) {
                dist +=
                    (sourceVars[s][v] - targetVars[t][v]) *
                    (sourceVars[s][v] - targetVars[t][v]) /
                    variances[v];
            }

            if(dist < minDist) {
                minDist = dist;
                currMatch = s;
            }
        }

        if(minDist >= nvars) {
            scoreSummary[0] += 1;
        } else {
            let score = Math.floor(10 * (1 - Math.sqrt(minDist/nvars)));
            targetScores[t] = score;
            scoreSummary[score] += 1;
            sourceCounts[currMatch] += 1;
        }
    }

    return {
        targetScores: targetScores,
        sourceCounts: sourceCounts,
        scoreSummary: scoreSummary
    };
}



function closestStandardScore(sourceVars, targetVars, variances) {
    let nvars = variances.length;
    let targetScores = Array(targetVars.length).fill(0); // don't need to fill
    let sourceCounts = Array(sourceVars.length).fill(0);
    let scoreSummary = Array(11).fill(0);
    let dist, currMatch, currScore, score;

    for(let t=0; t<targetVars.length; ++t) {
        score = 0

        for(let s=0; s<sourceVars.length; ++s) {
            dist = 0;

            for(let v=0; v<nvars; ++v) {
                dist = Math.max(dist,
                    (sourceVars[s][v] - targetVars[t][v]) *
                    (sourceVars[s][v] - targetVars[t][v]) /
                    variances[v]);
            }

            for(currScore = 0; currScore < 10; ++currScore){
                if(dist > CUTS[currScore]) { break; }
            }

            if (currScore > score) {
                score = currScore;
                currMatch = s;
            }

        }

        targetScores[t] = score;
        sourceCounts[currMatch] += 1;
        scoreSummary[score] += 1;
    }

    return {
        targetScores: targetScores,
        sourceCounts: sourceCounts,
        scoreSummary: scoreSummary
    };
}



/**
 * The complete list of available algorithms.
 *
 * This is purely a convenient way of fetching an algorithm given a string.
 */
const ALGORITHMS = {
    euclidean: euclidean,
    closestStandardScore: closestStandardScore
}



/**
 * The main entrypoint for running matches.
 */
export function runMatch(
    sourcePoints,
    targetPoints,
    matchSettings,
    vars // short for variances, which I use within the this function
) {
    sourcePoints = cloneFeatures(sourcePoints);
    targetPoints = cloneFeatures(targetPoints);

    let matchVars = _getMatchVals(VARIABLES, matchSettings);
    let variances = _getMatchVals(VARIANCES[vars], matchSettings);

    sourcePoints.features = sourcePoints.features.filter(p => p.properties.selected);
    let sourceVars = sourcePoints.features.map(p => _extractMatchVars(matchVars, p));
    if(!sourceVars.length)
        throw Error('no source stations');

    targetPoints.features = targetPoints.features.filter(p => p.properties.selected);
    let targetVars = targetPoints.features.map(p => _extractMatchVars(matchVars, p));
    if(!targetVars.length)
        throw Error('no target stations');

    let results = ALGORITHMS[matchSettings.algorithm](
        sourceVars, targetVars, variances);

    let maxMatches = results.sourceCounts.reduce((a, c) => Math.max(a, c), 0);
    let scaledMatches = null;
    if(maxMatches > 0) {
        scaledMatches = results.sourceCounts.map(v => v / maxMatches);
    } else {
        scaledMatches = results.sourceCounts;
    }

    for(let p=0; p<results.sourceCounts.length; ++p) {
        sourcePoints.features[p].properties = {
            realIndex: sourcePoints.features[p].properties.realIndex,
            score: results.sourceCounts[p],
            relativeScore: scaledMatches[p]
        }
    }

    for(let p=0; p<results.targetScores.length; ++p) {
        targetPoints.features[p].properties = {
            realIndex: targetPoints.features[p].properties.realIndex,
            score: results.targetScores[p]
        }
    }

    return {
        targets: targetPoints,
        sources: sourcePoints,
        scoreSummary: results.scoreSummary,
        maxSourceMatches: maxMatches
    }
}

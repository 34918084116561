import React, { Component } from 'react';

export class Saving extends Component {
    constructor(props) {
        super(props);
        this.state = { saveFileType: 'clm' };
        this.onFileTypeChosen = this.onFileTypeChosen.bind(this);
    }

    onFileTypeChosen(type) {
        this.setState({ saveFileType: type });
    }

    render() {
        return (<div id="save-as-div" className="message-holder">
            <div className="popupHeading">Save map</div>
            <br/>
            Save map as:
            <br/>

            <input type="radio" id="file-clm" name="fileExtension" value="clm"
                title="Save the map as a .clm (plain text) file."
                checked={this.state.saveFileType === 'clm'}
                onChange={() => this.onFileTypeChosen('clm')} />
            .clm file
            <br/>

            <input type="radio" id="file-asc" name="fileExtension" value="asc"
                title="Save the map as an ASCII grid file."
                checked={this.state.saveFileType === 'asc'}
                onChange={() => this.onFileTypeChosen('asc')}
                {...this.props.isGrid ? '' : {disabled: 'disabled'}} />
            ascii grid
            <br/>

            <input type="radio" id="file-tif" name="fileExtension" value="tif"
                title="Save the map as a .tif image file, suitable for use in MCAS"
                checked={this.state.saveFileType === 'tif'}
                onChange={() => this.onFileTypeChosen('tif')}
                {...(this.props.isGrid && this.props.canTiff) ? '' : {disabled: 'disabled'}} />
            .tif image
            <br/>

            <input type="radio" id="file-scores" name="fileExtension" value="scores"
                title="Save a CSV of the scores"
                checked={this.state.saveFileType === 'scores'}
                onChange={() => this.onFileTypeChosen('scores')}
                {...this.props.haveScores ? '' : {disabled: 'disabled'}} />
            score summary
            <br/>

            <hr/>

            <input type="button" className="message-OK" value="Save File"
                title="Save the map or score summary in the specified format"
                onClick={() => this.props.onClose(this.state.saveFileType)} />

            <input type="button" className="message-cancel" value="Finished"
                onClick={() => this.props.onClose(null)} />
            <br/>

        </div>);
    }
}
